export const fr_analytics = {
  analytics: 'Analítica',
  selectAll: 'Seleccionar todo',
  chats: 'Charlas',
  visits: 'Visitas',
  close: 'Cerrar',
  print: 'Imprimir',
  applyToAll: 'Aplicar a todo',
  nonFilteredGraphs: 'Gráficos no filtrados',
  noUsersAvailable: 'No hay usuarios disponibles',
  noData_Available: 'Datos no disponibles',
  dataBeingFetched: 'Espere, se están obteniendo los datos...',
  lastHours: 'Últimas 24 horas',
  lastDays: 'Últimos 30 días',
  lastMonths: 'Últimos 6 meses',
  pleaseWait: 'Espere, se están recuperando los datos ...',
  addMore: 'Añadir más',
  customize: 'Personalizar',
  forThePastYear: 'Durante el año pasado',
  quickStatsLegendTextFor6Months:
    'La comparación de datos de 6 meses con los 6 meses anteriores no está disponible actualmente.',
  chatsLeads: 'Chat vs cliente potencial',
  featuresUsage: 'Características Uso',
  chatReferrals: 'Referencias de chat',
  chatStatsBy: 'Estadísticas de chat por',
  topBottomLeadAccounts: 'Cuenta principal',
  topInactiveBillers: 'Principales facturadores inactivos',
  budgetPacing: 'Presupuesto / ritmo',
  agentImages: 'Imágenes del agente CTR',
  agentCTR: 'Agente CTR',
  accounts: 'Cuentas',
  filters: 'Filtros',
  clearAll: 'Limpiar todo',
  clear: 'Claro',
  features: 'Características',
  devices: 'Dispositivos',
  user: 'Usuario',
  companyKey: 'Clave de la empresa',
  typeOfBusiness: 'Tipos de negocios',
  ChartsShowHide: 'Mostrar / Ocultar gráficos',
  applyFilters: 'Aplicar filtros',
  apply: 'Aplicar',
  totalVisits: 'Total Visitas',
  totalChats: 'Total de chats',
  totalLeads: 'Ventas totales',
  totalBillableLeads: 'Clientes potenciales facturables totales',
  clickThroughRate: 'Clic por calificaciones',
  leadConversionRate: 'Conducir la conversión del',
  blankChats: 'Chats en blanco',
  activeCallConnect: 'Conexión de llamada activa',
  callConnectAttempts: 'Intentos de conexión de llamada',
  callConnectDuration: 'Duración de la conexión de llamada (minutos)',
  activeChatsForHosts: 'Chats activos para anfitriones',
  activeChats: 'Gatos activos',
  ctr: 'CTR',
  lcr: 'LCR',
  signedRetainers: 'Retenedores chamuscados',
  totalDocumentSignedLeads: 'Retenedores chamuscados',
  accountsActivation: 'Cuentas de Activación',
  topLeadGenerators: 'Generadores de plomo superiores',
  filterApplied: 'Filtros aplicados',
  quickStats: 'Estadísticas rápidas',
  charts: 'Gráficos',
  label1: 'Últimos 30 días',
  label2: 'Aumento de los 30 días anteriores',
  label3: 'Disminución de los 30 días anteriores',
  label4: 'En el',
  label5: 'Porcentaje',
  label6: 'Incrementos de la',
  label7: 'Disminuye de la',
  startTour: {
    label: 'Empezar recorrido'
  },
  tourIntro1:
    'Esto se inicia de la página de análisis y los widgets su Quick estadísticas',
  tourIntro2: 'Los gráficos ..',
  tourIntro3: 'Visitas totales ..',
  tourIntro4: 'Total de chats ..',
  tourIntro5: 'Ventas totales ..',
  tourIntro6: 'Leads total facturables ..',
  tourIntro7: 'Clic por calificaciones..',
  tourIntro8: 'Conducir la conversión del ..',
  tooltip1:
    'Los siguientes cuadros/gráficos no están filtrados por ningún filtro disponible como función, usuario, dispositivos, etc.',
  filtersTooltip1: 'Los cambios en esta sección se aplican a:',
  homeBannerHeading:
    'Nunca pierdas una llamada con Blazeo Voice, nuestro servicio de contestación de llamadas 24/7.',
  homeBannerTextDirect:
    'Obtenga contactos valiosos con Perfiles comerciales de Google a través de nuestros agentes de chat en vivo en Blazeo (anteriormente ApexChat).',
  homeBannerText:
    'Nuestros agentes capacitados pueden tomar mensajes, manejar la recepción, la calificación, el desvío y la gestión de llamadas, así como la firma de contratos y la programación de citas.',
  learnMore: 'Aprende más',
  answeredIn20: 'Llamadas contestadas en < 20 segundos',
  answeredIn30: 'Llamadas contestadas en < 30 segundos',
  answeredAfter30: 'Llamadas contestadas en > 30 segundos',
  inboundMinutes: 'Minutos Entrantes',
  outboundMinutes: 'Minutos Salientes',
  retainerSent: 'Retenedor Enviado',
  retainerConversion: 'Conversión de Retenedor',
  totalCalls: 'Total de Llamadas',
  totalMinutes: 'Total de Minutos'
};

export default fr_analytics;
